<template>
  <section class="top-filter">
    <div class="search mb12">
      <a-space>
        <a-button type="primary" @click="handleExport">
          导出
        </a-button>
      </a-space>
    </div>
    <section class="top-filter">
    <div class="mt12 mb12">
      <FilterLayout ref="filterLayoutRef" @doReset="handleReset" @doSearch="doSearch" @doCollapseEffect="handleResize">
        <a-form class="form rms-form" name="custom-validation" ref="formRef" :model="formState" layout="vertical">
          <a-form-item label="Team" name="orgThreeTwoList" v-auth="['system:todoAssignment:team']">
            <SelectWithAll class="form-item-inline" v-model:value="formState.orgThreeTwoList" placeholder="请选择"
              mode="multiple" showArrow allowClear :maxTagCount="1" @change="changeOrgThreeTwo">
              <a-select-option v-for="(item, index) in orgThreeTwoOps" :key="index" :value="item"><span :title="item">{{
                item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Offering" name="orgFourOneOneList" v-auth="['system:todoAssignment:offering']">
            <SelectWithAll class="form-item-inline" v-model:value="formState.orgFourOneOneList" placeholder="请选择"
              mode="multiple" showArrow allowClear :maxTagCount="1">
              <a-select-option v-for="(item, index) in orgFourOneOneOps" :key="index" :value="item"><span :title="item">{{
                item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Engagement Description" name="engagementCode">
            <a-input class="item" v-model:value="formState.engagementCode" placeholder="项目名称/项目code" allowClear
              autocomplete="off" />
          </a-form-item>
          <a-form-item label="Account" name="accountList">
            <SelectWithAll v-model:value="formState.accountList" :options="accountParamList" placeholder="请选择"
              optionFilterProp="label" allowClear>
            </SelectWithAll>
          </a-form-item>
          <!--      调令：调令RM PM状态内容不同 -->
          <!--      匹配：只有PM显示Status -->
          <a-form-item label="Status" name="status">
            <a-select class="item" v-model:value="formState.status" placeholder="请选择" :options="assignmentStatusOps"
              allowClear show-search :maxTagCount="1" />
          </a-form-item>
          <a-form-item label="Project Onboard Date" name="onBoradDateList">
            <a-range-picker class="item" v-model:value="formState.onBoradDateList" format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD" @change="handleOnboradDateChange" />
          </a-form-item>
          <a-form-item label="Estimate Release Date" name="releaseDateList">
            <a-range-picker class="item" v-model:value="formState.releaseDateList" format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD" @change="handleReleaseDateChange" />
          </a-form-item>
          <a-form-item label="搜索" name="nameCondition">
            <a-input class="item" v-model:value="formState.nameCondition" placeholder="人员姓名/ID Number/GGID" allowClear
              autocomplete="off" />
          </a-form-item>
        </a-form>
      </FilterLayout>
    </div>
    </section>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { useStore } from "vuex";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "TopFilter",
  components: {},
  props: {
    tabId: String,
    orgThreeTwoOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    orgFourOneOneOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    accountParamList: {
      type: Array,
      default: () => {
        return []
      }
    },
    isRM: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["search", "refresh-table", "getSize", "handleOrgChange", "exportClick", "getOrgList"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const assignmentStatusOps = ref([]);
    const formState = reactive({
      demandCondition: "",
      engagementCode: "",
      nameCondition: "",
      accountList: [],
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      status: null,
      onBoardBegin: '',
      onBoardEnd: '',
      estimateReleaseBegin: '',
      estimateReleaseEnd: '',
      onBoradDateList: [],
      releaseDateList: [],
    });

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      currentParams = cloneDeep(formState);
    }

    const handleResize = () => {
      emit("getSize")
    }

    const doSearch = () => {
      updateParams()
      emit("search", currentParams);
    };

    const handleReset = () => {
      formRef.value.resetFields();
      formState.onBoardBegin = '';
      formState.onBoardEnd = '';
      formState.estimateReleaseBegin = '';
      formState.estimateReleaseEnd = '';
      updateParams()
      emit("search", currentParams);
      // 重新获取team offering下拉
      if (props.isRM) {
        emit("getOrgList")
      }
    };

    const handleExport = () => {
      emit("exportClick", currentParams)
    };

    const getAssignmentTypes = () => {
      // 区分显示status筛选项的内容
      let dictStatusType;
      dictStatusType = isPm.value ? 'todoListAssPmStatus' : 'todoListAssStatus';
      $api
        .getDictionaryList({
          params: {
            dictType: "todoListAssStatus,todoListAssPmStatus",
          },
        })
        .then((res) => {
          assignmentStatusOps.value = res.data && res.data[dictStatusType] ?
            res.data[dictStatusType].map(({ dictCode, dictValue }) => ({
              label: dictValue,
              value: dictCode,
            })) : [];
        });
    };

    const handleOnboradDateChange = () => {
      formState.onBoardBegin = formState.onBoradDateList[0]
      formState.onBoardEnd = formState.onBoradDateList[1]
    }

    const handleReleaseDateChange = () => {
      formState.estimateReleaseBegin = formState.releaseDateList[0]
      formState.estimateReleaseEnd = formState.releaseDateList[1]
    }

    const store = useStore();
    const isPm = ref(false)
    const getUserRole = () => {
      const roles = store.state.app.user.roles;
      isPm.value = roles.indexOf("3") !== -1
    };

    const changeOrgThreeTwo = () => {
      formState.orgFourOneOneList = [];
      emit("handleOrgChange", formState.orgThreeTwoList)
    };

    onMounted(() => {
      getUserRole()
      getAssignmentTypes();
    });

    onActivated(() => {
      emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      handleReset,
      assignmentStatusOps,
      isPm,
      updateParams,
      handleOnboradDateChange,
      handleReleaseDateChange,
      changeOrgThreeTwo,
      handleResize,
      handleExport,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;

  .search {
    text-align: right;
  }
}
</style>
