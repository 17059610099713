<template>
  <div ref="welLetterTamEdit">
    <a-drawer :getContainer="()=>$refs.welLetterTamEdit" :visible="visible" class="drawer" width="86.4vw" :title="title" @close="handleCancel">
      <a-form class="form rms-form" name="custom-validation" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rulessave" :model="formState" layout="vertical">
        <a-row type="flex">
          <a-col :span="5">
            <a-form-item label="Team" name="orgThreeTwoList">
              <a-select :disabled="isFind"  class="form-item-inline" v-model:value="formState.orgThreeTwoList"
                        placeholder="请选择" showArrow allowClear :maxTagCount="1" @change="changeOrgThreeTwo" show-search>
                <a-select-option v-for="(item, index) in orgThreeTwoOps" :key="index" :value="item"><span
                    :title="item">{{
                    item }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="Offering" name="orgFourOneOneList">
              <a-select :disabled="isFind" class="form-item-inline" v-model:value="formState.orgFourOneOneList"
                        placeholder="请选择" showArrow allowClear :maxTagCount="1" @change="changeOrgFourOneOne" show-search>
                <a-select-option v-for="(item, index) in orgFourOneOneOps" :key="index" :value="item">
                  <span :title="item">{{item }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="Template Name" name="templateName">
              <a-input :disabled="isFind" :maxLength="80" class="form-item-inline" v-model:value="formState.templateName" placeholder="请输入模板名称" allowClear autocomplete="off" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="邮件主题" name="engagementCode" class="syrms-form-item">
              <a-input :disabled="isFind" class="item" v-model:value="formState.engagementCode" placeholder="请输入邮件主题" allowClear autocomplete="off" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="CC" name="ccPersonList" class="syrms-form-item">
              <SelectWithAll :disabled="isFind" class="item" v-model:value="formState.ccPersonList"
                             :placeholder="isFind ? '' : '请选择'" allowClear showArrow mode="multiple" :maxTagCount="1"
                             @dropdownVisibleChange="ccPersonListChange">
                <a-select-option v-for="item in CClist" :key="item.fullName + ' ' + item.email" :value="item.fullName + ' ' + item.email">
                  <span :title="item.fullName + ' ' + item.email">{{item.fullName + ' ' + item.email }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- <p class="text">欢迎
          <span class="operationLink">{{ templateData.fullName }}</span>正式加入
          <span class="operationLink">{{ templateData.orgFourOneOne }}</span>团队,
          <span class="operationLink">{{ templateData.orgFourOneOne }}</span>
          部门负责人是<span class="operationLink">{{ templateData.departmentManager }}</span>。 -->
      <a-textarea :disabled="isFind" class="textarea box-padding" v-model:value="remarks" :maxlength="500"
                  :auto-size="{ minRows: 1, }" showCount />
      <div class="btn-box box-padding">
        <a-button v-show="!isFind" class="btn-add" type="primary" @click="handledadd">
          <PlusOutlined />新增
        </a-button>
      </div>
      <a-table v-if="isFind" class="box-padding" :class="[isFind ? 'nodrag' : '']" style="margin-top: 15px;" :dataSource="dataList"
               :columns="columnsFind" :pagination="false" :rowKey="(record) => record.userId">
      </a-table>
      <a-table v-else class="box-padding" :customRow="customRow" style="margin-top: 15px;" :dataSource="dataList" :columns="columns"
               :pagination="false" :rowKey="(record) => record.userId">
        <template #position="{ index }">
          <a-select :disabled="index === 0" style="width: 130px;" @change="changePosition($event, index)"
                    v-model:value="dataList[index].position" placeholder="请选择" :options="positionlist" />
        </template>
        <template #email="{ index,record }">
          <a-select :disabled="index === 0" style="width: 180px;" @change="changeemail($event, index)"
                    v-model:value="dataList[index].email" placeholder="请选择" show-search @click="emailClick(index,record)">
            <a-select-option v-for="item in CClistRh" :value="item.email" :key="item.email">{{
                item.email }}</a-select-option>
          </a-select>
        </template>
        <template #description="{ index }">
          <a-textarea :disabled="index === 0" style="position: relative" v-model:value="dataList[index].description"
                      :auto-size="{ minRows: 1, maxRows: 3 }" :maxlength="500" />
        </template>
        <template #action="{ index }">
          <a-space class="flex-box">
            <!-- <span v-if="dataList.length === (index + 1)" class="operationLink" @click="handledadd(index)">添加</span> -->
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">删除</span>
              </template>
              <span class="operationLinkdel icon-btn" v-show="index !== 0" @click="handledel(index)"><DeleteOutlined :style="{display:'block'}" /></span>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>

      <!-- 调令 -->
      <div class="box-padding" style="margin-top: 30px;">
        <div class="add-comment gray">
          <RightOutlined class="img-svg" v-if="!showassignmentdata" @click="showClick" />
          <DownOutlined class="img-svg" v-if="showassignmentdata" @click="showClick" />
          <p>添加调令内容:如下是您即将进入的项目信息，详细信息请参考 （ <span class="operationLinklist">staffingmanagement.ch@capgemini.com</span>
            ）邮箱发送的调令邮件，邮件标题【RMS-Assignment Letter】顾问-项目名称。
          </p>
        </div>
        <div v-if="showassignmentdata">
          <!-- <a-button v-show="!isFind" class="btn-addIdc" @click="handledTransferOrder" type="primary">
              <PlusOutlined />新增
          </a-button> -->
          <a-table v-if="isFind" :dataSource="assignmentdata" :columns="columns2" :pagination="false">
          </a-table>
          <a-table v-else :dataSource="assignmentdata" :columns="columns2" :pagination="false">
            <template #wlPl="{ index }">
              <a-textarea v-model:value="assignmentdata[index].wlPl" :auto-size="{ minRows: 1, maxRows: 1 }" />
            </template>
            <template #wlAccount="{ index }">
              <a-textarea v-model:value="assignmentdata[index].wlAccount"
                          :auto-size="{ minRows: 1, maxRows: 1 }" />
            </template>
            <template #engagementDescription="{ index }">
              <a-textarea v-model:value="assignmentdata[index].engagementDescription"
                          :auto-size="{ minRows: 1, maxRows: 1 }" />
            </template>
            <template #engagementManager="{ index }">
              <a-select style="width: 180px;" v-model:value="assignmentdata[index].engagementManager"
                        placeholder="请选择" show-search>
                <a-select-option v-for="item in CClist" :value="item.fullName + ' ' + item.email"
                                 :key="item.fullName + ' ' + item.email">{{
                    item.fullName + ' ' + item.email }}</a-select-option>
              </a-select>
            </template>
            <template #action="{ index }">
              <a-space class="flex-box">
                <a-tooltip class="tooltip-list" placement="top" color="#fff">
                  <template #title>
                    <span class="font-color">删除</span>
                  </template>
                  <span class="operationLinkdel icon-btn" @click="handleOrderDel(index)"><DeleteOutlined :style="{display:'block'}" /></span>
                </a-tooltip>
              </a-space>
            </template>
          </a-table>
        </div>
      </div>
      <div class="box-padding" style="margin-top: 30px;">
        <div class="gray">
          <p>如下是您所属 <span class="operationLinklist">{{ templateData.orgFourOneOne }}</span>团队的IDC
            Code,适用于年假、病假、培训等情况.每个IDC
            Code下面有多种Task,可在Timecard系统里点击"Task"字段下面的放大镜图标,然后点击"go"进行选择.如不清楚适用不同情况应使用哪种IDC Code,请向RM咨询。</p>
        </div>
        <a-button v-show="!isFind" class="btn-addIdc" type="primary" @click="handledaddIdc">
          <PlusOutlined />新增
        </a-button>
        <a-button v-show="!isFind" class="btn-addIdc" style="margin-right: 5px" type="primary" @click="handledUpdateIdc">
          更改
        </a-button>
        <a-table v-if="isFind" :class="[isFind ? 'nodrag' : '']" :dataSource="idcCodeList" :columns="columns3"
                 :pagination="false">
        </a-table>
        <a-table v-else :customRow="customRowIdc" :dataSource="idcCodeList" :columns="columns3" :pagination="false">
          <template #entity="{ index }">
            <a-select style="width: 100px;" v-model:value="idcCodeList[index].entity" placeholder="请选择"
                      :options="entityList" allowClear @change="entityChange($event, index)" />
          </template>
          <template #organization="{ index }">
            <a-select style="width: 200px;" v-model:value="idcCodeList[index].organization" placeholder="请选择"
                      show-search allowClear @change="organizationChange($event, index)" showArrow>
              <a-select-option v-for="item in organizationList" :value="item" :key="item">
                <span :title="item">{{ item }}</span></a-select-option>
            </a-select>
          </template>
          <template #projectNumber="{ index, record }">
            <a-select style="width: 200px;" v-model:value="idcCodeList[index].projectNumber"
                      @change="projectNumberChange($event, index)" placeholder="请选择" show-search allowClear
                      @dropdownVisibleChange="projectNumberOps($event, record)">
              <a-select-option v-for="item in projectNumlists" :value="item.value" :key="item.key">{{
                  item.label }}</a-select-option>
            </a-select>
          </template>
          <template #projectName="{ index, record }">
            <a-select style="width: 200px;" v-model:value="idcCodeList[index].projectName"
                      @change="projectNameChange($event, index)" placeholder="请选择" show-search allowClear
                      @dropdownVisibleChange="projectNameOps($event, record)" showArrow>
              <a-select-option v-for="item in projectNamelists" :value="item" :key="item">
                <span :title="item">{{ item }}</span></a-select-option>
            </a-select>
          </template>
          <template #action="{ index }">
            <a-space class="flex-box">
              <a-tooltip class="tooltip-list" placement="top" color="#fff">
                <template #title>
                  <span class="font-color">删除</span>
                </template>
                <span class="operationLinkdel icon-btn" @click="handledelIdc(index)"><DeleteOutlined :style="{display:'block'}" /></span>
              </a-tooltip>
            </a-space>
          </template>
        </a-table>
      </div>
      <div class="box-padding" style="margin-top: 30px;">
        <p><span class="operationLink-entry">入职Checklist：</span></p>
        <div class="gray">
          <p>为帮助您更好的快速适应公司和团队，请仔细阅读附件入职指引PPT, 并建议于入职两周内解锁信息：</p>
          <p>•培训&必修课（未按时完成课程，账号将会被冻结，且完成结果与Learning KPI关联） </p>
          <p>•预计入职材料交齐一周后可使用GTE考勤系统Timecard（累计两次未按时提交，将会扣除500元） </p>
          <p>•入职一周内完成RMS系统信息维护&凯捷模板简历更新（请参考附件CG China CV
            Template编辑个人简历，根据PPT内RMS系统登陆方法及维护要求，修改个人信息并上传简历） </p>
        </div>
      </div>
      <!-- 简历模板列表 -->
      <div class="table box-padding">
            <a-table :data-source="tableListResume" size="small" :columns="columnsResume" :rowKey="(record) => record.id"
                class="gray-table" :rowClassName="(record, index) => (index / 1 === index ? 'table-striped' : null)
                    " :pagination="false">
          <template #icon="{ record, text }">
            <FileZipFilled class="zip" v-if="record.fileName.indexOf('zip') !== -1" />
            {{ text }}
          </template>
        </a-table>
        <p class="blue">
          <ExclamationCircleOutlined />&nbsp;&nbsp;默认附件，凯捷统一模板不可更改
        </p>
      </div>
      <!-- 入职指引列表 -->
      <div class="table box-padding">
        <a-button v-if="tableListEntry == null || tableListEntry?.length == 0 && !isFind" class="others-files" type="primary"
                  @click="uploadClick1">上传入职指引</a-button>
        <a-table :data-source="tableListEntry" size="small" :columns="columnsEntry" :rowKey="(record) => record.id"
                 class="gray-table" :rowClassName="(record, index) => (index / 1 === index ? 'table-striped' : null)
                    " :pagination="false">
          <template #icon="{ record, text }">
            <FilePptFilled class="ppt" v-if="record.fileName.indexOf('ppt') !== -1" />
            <FileZipFilled class="zip" v-else-if="record.fileName.indexOf('zip') !== -1" />
            <FileWordFilled class="word" v-else />
            {{ text }}
          </template>
          <template #operation="{ record }">
            <a-space class="flex-box">
              <a-tooltip class="tooltip-list" placement="top" color="#fff">
                <template #title>
                  <span class="font-color">预览</span>
                </template>
                <span v-if="record.fileName?.indexOf('zip') !== -1" class="operationLinkgray icon-btn"
                      v-auth="['system:roleMaintain:preview']"><FileImageOutlined :style="{display:'block'}" /></span>
                <span v-else class="operationLinklist icon-btn" @click="preview(record, 1)"
                      v-auth="['system:roleMaintain:preview']"><FileImageOutlined :style="{display:'block'}" /></span>
              </a-tooltip>
              <a-tooltip class="tooltip-list" placement="top" color="#fff">
                <template #title>
                  <span class="font-color">上传</span>
                </template>
                <span v-if="!isFind" class="operationLinklist icon-btn" @click="uploadClick(record)"
                      v-auth="['system:roleMaintain:preview']"><UploadOutlined :style="{display:'block'}" /></span>
              </a-tooltip>
              <a-tooltip class="tooltip-list" placement="top" color="#fff">
                <template #title>
                  <span class="font-color">删除</span>
                </template>
                <span v-if="!isFind" class="operationLinkdel icon-btn" @click="deleteClick(record)"
                      v-auth="['system:roleMaintain:preview']"><DeleteOutlined :style="{display:'block'}" /></span>
              </a-tooltip>

                        <!-- <span v-if="!isFind" @click="onselectFun(record)">
                            <a-upload name="file" :accept="accept" action="" :showUploadList="false"
                                :beforeUpload="beforeUpdate" :customRequest="update">
                                <span class="operationLink" v-auth="['system:roleMaintain:update']">更新</span>
                            </a-upload>
                        </span> -->
                    </a-space>
                </template>
            </a-table>
            <p class="blue">
                <ExclamationCircleFilled />&nbsp;&nbsp;默认附件，可在入职指引模块更新，也可通过上传更新
            </p>
        </div>
        <!-- 其他附件列表 -->
        <div class="table box-padding">
            <a-button v-show="!isFind" class="others-files" type="primary" @click="uploadOthersFiles">上传其他附件</a-button>
            <a-table :data-source="tableListOthers" size="small" :columns="columnsOthers" :rowKey="(record) => record.id"
                class="gray-table" :rowClassName="(record, index) => (index / 1 === index ? 'table-striped' : null)
                    " :pagination="false">
          <template #icon="{ record, text }">
            <FilePptFilled class="ppt" v-if="record.fileName.indexOf('ppt') !== -1" />
            <FileZipFilled class="zip" v-else-if="record.fileName.indexOf('zip') !== -1" />
            <FileWordFilled class="word" v-else />
            {{ text }}
          </template>
          <template #operation="{ record }">
            <a-space class="flex-box">
              <!-- <span @click="onselectFun(record)">
                  <a-upload name="file" :accept="accept" action="" :showUploadList="false"
                      :beforeUpload="beforeUpdate" :customRequest="update">
                      <span class="operationLink" v-auth="['system:roleMaintain:update']">更新</span>
                  </a-upload>
              </span> -->
              <!-- <span class="operationLink" @click="download(record)"
                  v-auth="['system:roleMaintain:download']">下载</span> -->

              <a-tooltip class="tooltip-list" placement="top" color="#fff">
                <template #title>
                  <span class="font-color">预览</span>
                </template>
                <span v-if="record.fileName?.indexOf('zip') !== -1" class="operationLinkgray icon-btn"
                      v-auth="['system:roleMaintain:preview']"><FileImageOutlined :style="{display:'block'}" /></span>
                <span v-else class="operationLinklist icon-btn" @click="preview(record, 2)"
                      v-auth="['system:roleMaintain:preview']"><FileImageOutlined :style="{display:'block'}" /></span>
              </a-tooltip>
              <a-tooltip class="tooltip-list" placement="top" color="#fff">
                <template #title>
                  <span class="font-color">删除</span>
                </template>
                <span v-show="!isFind" class="operationLinkdel icon-btn" @click="deleteOtherFilesFun(record)"
                      v-auth="['system:roleMaintain:preview']"><DeleteOutlined :style="{display:'block'}" /></span>
              </a-tooltip>
            </a-space>
          </template>
        </a-table>
        <p class="blue padding-btm">
          <ExclamationCircleOutlined />&nbsp;&nbsp;最多上传三个附件
        </p>
      </div>
      <div v-if="!isFind" class="feature-lineright bottom-btn">
        <a-button v-show="!isFind" style="margin-left:8px;" @click="handleCancel">取消</a-button>
        <a-button v-show="!isFind" type="primary" style="margin-left:8px;" @click="handleOthersSave">另存为</a-button>
        <a-button v-show="!isFind" type="primary" style="margin-left:8px;" @click="handleConfirm('edit')">保存</a-button>
      </div>
      <!-- 预览 -->
      <a-modal v-model:visible="previewVisible" title="预览" width="62.5vw" @cancel="closePreviewModal">
        <template #footer>
          <a-button @click="closePreviewModal">关闭</a-button>
        </template>
        <iframe id="printIframe" :src="previewUrl" style="width: 100%; height: 70vh; border: none"></iframe>
      </a-modal>
      <!-- 上传附件 -->
      <a-modal
          class="demo-modal"
          width="29.2vw"
          v-model:visible="uploadOthersVisible"
          title="上传其他附件"
          cancelText="取消"
          okText="确定"
          @ok="handleUploadOk"
          centered
      >
        <div>
          <a-upload-dragger v-model:fileList="fileList" name="file" :multiple="true"
                            accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar" :beforeUpload="handleBeforeUpload"
                            :remove="removeFile">
            <p class="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p class="ant-upload-text">上传文件</p>
            <div class="ant-upload-text-alert">
                <p>1、可上传除入职指引和简历模板外其他附件，最多上传3个附件，小于50M</p>
                <p>2、可上传附件格式doc,docx,ppt,pptx,xls,xlsx,txt,pdf,7z,zip,rar</p>
            </div>
          </a-upload-dragger>
        </div>
      </a-modal>
      <!-- 另存为弹窗 -->
      <a-modal
          :getContainer="()=>$refs.welLetterTamEdit"
          class="demo-modal"
          width="29.2vw"
          v-model:visible="othersSaveVisible"
          title="另存为"
          cancelText="取消"
          okText="确定"
          @ok="handleOthersUploadOk"
          centered
      >
        <div>
          <a-form class="form rms-formlc" name="custom-validation" :model="formStateOthers" layout="vertical">
            <a-form-item label="Team" name="orgThreeTwoList">
              <a-select disabled class="form-item-inline" style="width:224px;"
                        v-model:value="formStateOthers.orgThreeTwoList" placeholder="请选择" showArrow allowClear
                        :maxTagCount="1" @change="changeOrgThreeTwoOthers" show-search>
                <a-select-option v-for="(item, index) in orgThreeTwoOpsOthers" :key="index" :value="item"><span
                    :title="item">{{
                    item }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Offering" name="orgFourOneOneList">
              <a-select disabled class="form-item-inline" style="width:224px;"
                        v-model:value="formStateOthers.orgFourOneOneList" placeholder="请选择" showArrow allowClear
                        :maxTagCount="1" @change="changeOrgFourOneOneOthers" show-search>
                <a-select-option v-for="(item, index) in orgFourOneOneOpsOthers" :key="index"
                                 :value="item"><span :title="item">{{
                    item }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Template Name" name="templateName" style="margin-top: 20px;">
              <a-input class="item" style="width:460px;" :maxLength="80"
                       v-model:value="formStateOthers.templateName" placeholder="请输入模板名称" allowClear
                       autocomplete="off" />
            </a-form-item>
          </a-form>
        </div>
      </a-modal>
      <import-modal v-model:visible="modalVisible" apiFuncName="uploadOrganizations"
                    :uploadParams="{ templateId: templateId }" source="OrientationGuidelines"
                    accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar" @closeModal="handleCloseModal"
                    @resetTable="handleResetTable">
      </import-modal>
      <import-modal v-model:visible="modalVisible1" apiFuncName="uploadGuide" :uploadParams="offerObj"
                    source="OrientationGuidelines" accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar"
                    @closeModal="handleCloseModal1" @resetTable="handleResetTable1">
      </import-modal>
      <UpdateIdc
          v-model:visible="updateIdcVisible"
          @close-modal="closeUpdateIdc"
          :updateIdcOffering="updateIdcOffering"
          @submitModal="updateIdcData"
      />
    </a-drawer>
    <ModalBox :getContainer="()=>$refs.welLetterTamEdit" v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  </div>
</template>
<script>
import {
  UploadOutlined,
  RightOutlined,
  DownOutlined,
  PlusOutlined,
  FilePptFilled,
  FileWordFilled,
  FileZipFilled,
  ExclamationCircleOutlined,
  DeleteOutlined,
  FileImageOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, ref, onMounted, reactive } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from 'ant-design-vue';
import ImportModal from "@/components/ImportModal.vue";
import UpdateIdc from "@/views/home/WelLetterManagement/components/UpdateIdc";
import ModalBox from '@/components/ModalBox'

const columns = [
    {
        title: "姓名",
        dataIndex: "fullName",
        width: 200,
        ellipsis: true,
    },
    {
        title: "职位",
        dataIndex: "position",
        width: 200,
        ellipsis: true,
        slots: { customRender: "position" },
    },
    {
        title: "联系方式",
        dataIndex: "email",
        width: 250,
        ellipsis: true,
        slots: { customRender: "email" },
    },

    {
        title: "负责事宜",
        dataIndex: "description",
        ellipsis: true,
        slots: { customRender: "description" },
    },
    {
        title: `操作`,
        dataIndex: "action",
        width: 80,
        ellipsis: true,
        slots: { customRender: "action" },
        fixed: "right",
    },
];
const columnsFind = [
    {
        title: "姓名",
        dataIndex: "fullName",
        ellipsis: true,
        width: 200,
    },
    {
        title: "职位",
        dataIndex: "position",
        ellipsis: true,
        width: 200,
        slots: { customRender: "position" },
    },
    {
        title: "联系方式",
        dataIndex: "email",
        ellipsis: true,
        width: 250,
        slots: { customRender: "email" },
    },

    {
        title: "负责事宜",
        dataIndex: "description",
        ellipsis: true,
        slots: { customRender: "description" },
    }
];
const columns2 = [
    {
        title: "P&L",
        dataIndex: "wlPl",
        ellipsis: true,
        width: 200,
        slots: { customRender: "wlPl" },
    },
    {
        title: "Account",
        dataIndex: "wlAccount",
        ellipsis: true,
        width: 200,
        slots: { customRender: "wlAccount" },
    },
    {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        ellipsis: true,
        slots: { customRender: "engagementDescription" },
    },
    {
        title: "Engagement Manager",
        dataIndex: "engagementManager",
        ellipsis: true,
        slots: { customRender: "engagementManager" },

    },
    {
        title: "操作",
        dataIndex: "option",
        width: 80,
        ellipsis: true,
        slots: { customRender: "action" },
        fixed: "right",
    }
];
const columns3 = [
    {
        title: "Entity",
        dataIndex: "entity",
        ellipsis: true,
        width: 150,
        slots: { customRender: "entity" },
    },
    {
        title: "Organization",
        dataIndex: "organization",
        ellipsis: true,
        slots: { customRender: "organization" },
    },
    {
        title: "Project Number",
        dataIndex: "projectNumber",
        ellipsis: true,
        slots: { customRender: "projectNumber" },
    },
    {
        title: "Project Name",
        dataIndex: "projectName",
        ellipsis: true,
        slots: { customRender: "projectName" },
    },
    {
        title: "Project Type",
        dataIndex: "projectType",
        ellipsis: true,
        width: 160,
    },
    {
        title: "操作",
        dataIndex: "option",
        width: 80,
        ellipsis: true,
        slots: { customRender: "action" },
        fixed: "right",
    },
]
const columnsResume = [
    {
        title: "简历模板",
        dataIndex: "fileName",
        ellipsis: true,
        width: 600,
        slots: { customRender: "icon" },
    },
    {
        title: "更新时间",
        dataIndex: "operationTime",
        ellipsis: true,
    },
    {
        title: "",
        dataIndex: "operation",
        ellipsis: true,
        width: 10,
        slots: { customRender: "operation" },
    },
];
const columnsEntry = [
    {
        title: "入职指引",
        dataIndex: "fileName",
        ellipsis: true,
        width: 600,
        slots: { customRender: "icon" },
    },
    {
        title: "更新时间",
        dataIndex: "operationTime",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "operation",
        ellipsis: true,
        width: 140,
        slots: { customRender: "operation" },
        fixed: "right",
    },
];
const columnsOthers = [
    {
        title: "其他附件",
        dataIndex: "fileName",
        ellipsis: true,
        width: 600,
        slots: { customRender: "icon" },
    },
    {
        title: "更新时间",
        dataIndex: "operationTime",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "operation",
        ellipsis: true,
        width: 100,
        slots: { customRender: "operation" },
        fixed: "right",
    },
];

const positionlist = [
    // { label: "Line Manager", value: "Line Manager", key: "1" },
    { label: "Resource Manager", value: "Resource Manager", key: "2" },
    { label: "HRBP", value: "HRBP", key: "3" }
]
const entityList = [
    { label: "PRC", value: "PRC", key: "1" },
    { label: "PRK", value: "PRK", key: "2" },
    { label: "PRJ", value: "PRJ", key: "3" }
]
const rulessave = {
    orgThreeTwoList: [
        {
            required: true,
            message: 'Please select team name',
            trigger: 'change',
        }
    ],
    orgFourOneOneList: [
        {
            required: true,
            message: 'Please select offering name',
            trigger: 'change',
        },
    ],
    templateName: [
        {
            required: true,
            message: 'Please select template name',
            trigger: 'blur',
        },
    ],
    engagementCode: [
        {
            required: true,
            message: 'Please input engagementCode name',
            trigger: 'blur',
        },
    ],
    // ccPersonList: [
    //     {
    //         required: true,
    //         message: 'Please select email name',
    //         trigger: 'change',
    //     },
    // ]
}
export default defineComponent({
    name: "WelcomeLetterTamplateEdit",
    components: {
        UpdateIdc,
        UploadOutlined,
        DownOutlined,
        RightOutlined,
        PlusOutlined,
        FilePptFilled,
        FileWordFilled,
        ImportModal,
        FileZipFilled,
        ExclamationCircleOutlined,
        ModalBox,
        DeleteOutlined,
        FileImageOutlined,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        userId: {
            type: String,
            default: ''
        },
        offering: {
            type: String,
            default: ''
        },
        isFind: {
            type: Boolean,
            default: false
        },
        visible: {
          type: Boolean,
          default: false
        }
    },
    setup(props, { emit }) {
        const { $api } = useGlobalPropertyHook();
        const templateData = ref({})
        const remarks = ref('')
        const assignmentdata = ref([])
        const dataList = ref([])
        const idcCodeList = ref([])
        const modalVisible1 = ref(false)
        const projectNumlists = ref([])
        const CClist = ref([])
        const CClistRh = ref([])
        const showassignmentdata = ref(false)
        const uploadOthersVisible = ref(false)
        const othersSaveVisible = ref(false)
        let tableListResume = ref([])
        let tableListEntry = ref([])
        let tableListOthers = ref([])
        const organizationList = ref([])
        const projectNamelists = ref([])
        const allRelation = ref([])
        // tableListResume = [
        //     {
        //         file_name: '123.ppt',
        //         uploadTime: '2023-01-01'
        //     },
        //     {
        //         file_name: '456.ppt',
        //         uploadTime: '2023-01-01'
        //     }
        // ]
        const modalVisible = ref(false)
        const templateId = ref('')
        const formState = reactive({
            nameCondition: "",
            templateName: "",
            status: null,
            orgThreeTwoList: [],
            orgFourOneOneList: [],
            statusList: [],
            capgeminiStratDate: [],
            ccPersonList: []
        });
        const formStateOthers = reactive({
            templateName: "",
            orgThreeTwoList: [],
            orgFourOneOneList: []
        });
        let offerObj = reactive({
            offering: ""
        })
        const orgThreeTwoOps = ref([]);
        const orgFourOneOneOps = ref([]);
        const orgThreeTwoOpsOthers = ref([]);
        const orgFourOneOneOpsOthers = ref([]);
        const getEMOptions = () => {
            $api.findAllStaffs().then((res) => {
                CClist.value = res.data;
            });
        };
        const handleCloseModal = () => {
            modalVisible.value = false
        }
        const handleCloseModal1 = () => {
            modalVisible1.value = false
        }
        const handleResetTable = () => {
            changeOrgFourOneOne(tempOffering, 'update');
        }
        const handleResetTable1 = () => {
            changeOrgFourOneOne(tempOffering, 'update');
        }
        const uploadClick = (record) => {
            templateId.value = record.relationId
            modalVisible.value = true

        }
        //删除入职指引
        const deleteClick = (record) => {
            console.log('record', record.relationId)
            content.value = `是否确认删除${props.offering}对应的入职指引附件`
            showIconCom.value = 'ExclamationCircleOutlined'
            modalData.value = {record}
            flag.value = 6
            modelValue.value = true
            /*Modal.confirm({
                title: `是否确认删除${props.offering}对应的入职指引附件`,
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确认',
                width: "30%",
                centered: true,
                onOk() {
                    $api.organizationsDeleteFile(record.id, props.offering).then((res) => {
                        if (res.msg == '当前有人员引用了该入职指引，不可删除') {
                            message.error('当前有人员引用了该入职指引，不可删除！');
                        } else {
                            getWelcomeLetterTemplateManage(props.userId, props.offering)
                            message.success('删除成功！');
                        }

                    })
                },
                cancelText: '取消',
            });*/
        }

        const uploadClick1 = () => {
            modalVisible1.value = true

        }
        // 另存为弹窗
        const handleOthersSave = () => {
            othersSaveVisible.value = true;
        }
        const getIdcOptions = () => {
            $api.getIdcCodes().then((res) => {
                projectNumlists.value = res.data.relations.map((item, index) => {
                    return {
                        label: item.projectNumber,
                        value: item.projectNumber,
                        key: index,
                        organization: item.organization,
                        projectName: item.projectName,
                        projectType: item.projectType,
                        entity: item.entity
                    }
                });
                organizationList.value = res.data.organization;
                projectNamelists.value = res.data.projectName;
                allRelation.value = res.data.relations
            });
        };
        //查询org3.2/org4.2
        const getOrgList = () => {
            $api
                .getOrgThree_FourList()
                .then((res) => {
                    const { orgThreeTwo, orgFourOneOne } = res.data;
                    orgThreeTwoOps.value = orgThreeTwo;
                    orgFourOneOneOps.value = orgFourOneOne;
                    orgThreeTwoOpsOthers.value = orgThreeTwo;
                    orgFourOneOneOpsOthers.value = orgFourOneOne;
                    updateIdcOffering.value = orgFourOneOne;
                });
        };
        const uploadOthersFiles = () => {
            uploadOthersVisible.value = true;
        }
        const handleUploadOk = () => {
            // tableListOthers.value = fileList.value.map(item => {
            //     return {
            //         fileName: item.name,
            //         operationTime: item.lastModifiedDate
            //     }
            // })
            uploadFiles();
            uploadOthersVisible.value = false;
        }
        const uploadFiles = () => {
            const formData = new FormData();
            fileList.value.forEach((item) => {
                formData.append("file", item);
            });
            $api.welcomeLetterTemplateUpload(formData).then(res => {
                if (res.code == 200) {
                    tableListOthers.value = res.data;
                }
            })
        }
        const handleOthersUploadOk = () => {
            handleConfirm('other');
        }
        //预览
        const previewVisible = ref(false)
        const previewUrl = ref()
        const preview = async (item, type) => {
            const { data } = type === 1 ? await $api.previewManual(item.id) : await $api.previewOthers(item.id);
            previewVisible.value = true
            if (data.indexOf('pdf') !== -1) {
                previewUrl.value = data
            } else {
                previewUrl.value = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(data)}`;
            }
        }
        const closePreviewModal = () => {
            previewVisible.value = false
            previewUrl.value = ""
        }
        // const getstaffWelcomeLetterTemplate = (userId) => {
        //     $api.getstaffWelcomeLetterTemplate(userId).then(res => {
        //         console.log(res);
        //         templateData.value = res.data.template
        //         ccPersonList.value = res.data.ccPersonList ? res.data.ccPersonList : []
        //         res.data.idcCodeList.forEach(item => {
        //             item.templateId = res.data.template.id
        //         })
        //         idcCodeList.value = res.data.idcCodeList
        //         fileList.value = res.data.staffFiles
        //         fileList.value.forEach(item => {
        //             item.name = item.fileName
        //         })
        //         dataList.value = res.data.staffWelcomeLetterContactsItems
        //         assignmentdata.value[0] = res.data.template
        //         showassignmentdata.value = assignmentdata.value[0].engagementManager ? false : true
        //     })
        // }
        // 查看
        const getWelcomeLetterTemplateManage = (userId, offering) => {
            offerObj.offering = offering;
            $api.findDefineTemplate(userId, offering).then(res => {
                if (res.code == 200) {
                    formState.orgThreeTwoList = [res.data.team];
                    formState.orgFourOneOneList = [res.data.offering];
                    formStateOthers.orgThreeTwoList = [res.data.team];
                    formStateOthers.orgFourOneOneList = [res.data.offering];
                    tempOffering = res.data.offering;
                    formState.templateName = res.data.name;
                    formStateOthers.templateName = res.data.name;
                    formState.engagementCode = res.data.subject;
                    formState.ccPersonList = res.data.ccPerson ? res.data.ccPerson : [];
                    dataList.value = [
                        {
                            fullName: '-',
                            position: 'Line Manager',
                            email: '-',
                            description: '工作目标设定及安排，个人发展，绩效管理等'

                        }
                    ]
                    if (res.data.items?.length > 0) {
                        dataList.value = [...dataList.value, ...res.data.items]
                    }
                    // dataList.value = res.data.items;
                    tableListResume.value = res.data.files.templateFileList;
                    tableListEntry.value = res.data.files.guideFileList;
                    tableListOthers.value = res.data.files.otherFileList;
                    fileList.value = res.data.files.otherFileList;
                    console.log(res.data.files.otherFileList);
                    remarks.value = res.data.remarks;
                    idcCodeList.value = res.data.idcCodes;
                    assignmentdata.value = res.data.assignments;
                }
            });
        }
        const changeemail = (value, index) => {
            CClist.value.forEach(item => {
                if (item.email === value) {
                    dataList.value[index].fullName = item.fullName
                }
            })
        }
        let CClistRh1 = ref([])
        let CClistRh2 = ref([])
        const getHRBPAndRMDataFun = () => {
            $api.getHRBPAndRMData().then(res => {
                CClistRh1.value = res.data[0].info;
                CClistRh2.value = res.data[1].info;
            })
        }
        const changePosition = (value, index) => {
            if (value === 'HRBP') {
                CClistRh.value = CClistRh2.value;
                dataList.value[index].description = "人事政策流程，如五险一金、公司福利、休假制度等"
            } else {
                CClistRh.value = CClistRh1.value;
                dataList.value[index].description = "项目安排(上下项目信息、潜在项目机会协调、UT管理、code填写)考勤系统使用辅导"
            }
        }
        const emailClick = (index,record) => {
          if (record.position === 'HRBP') {
            CClistRh.value = CClistRh2.value;
            dataList.value[index].description = "人事政策流程，如五险一金、公司福利、休假制度等"
          } else if (record.position === 'Resource Manager') {
            CClistRh.value = CClistRh1.value;
            dataList.value[index].description = "项目安排(上下项目信息、潜在项目机会协调、UT管理、code填写)考勤系统使用辅导"
          } else {
            CClistRh.value = [];
          }
        }
        const entityChange = (value, index) => {
            idcCodeList.value[index].projectName = ''
            idcCodeList.value[index].projectNumber = ''
            idcCodeList.value[index].projectType = ''
        }
        const organizationChange = (value, index) => {
            idcCodeList.value[index].projectName = ''
            idcCodeList.value[index].projectNumber = ''
            idcCodeList.value[index].projectType = ''
        }
        const projectNumberChange = (value, index) => {
            if (value) {
                projectNumlists.value.forEach(item => {
                    if (item.value === value) {
                        idcCodeList.value[index].projectName = item.projectName,
                            idcCodeList.value[index].projectType = item.projectType,
                            idcCodeList.value[index].organization = item.organization,
                            idcCodeList.value[index].entity = item.entity
                    }
                })
            } else {
                idcCodeList.value[index].projectName = ''
                idcCodeList.value[index].projectType = ''
            }
        }
        const projectNameChange = (value, index) => {
            if (value) {
                allRelation.value.forEach(item => {
                    if (item.projectName === value) {
                        idcCodeList.value[index].projectNumber = item.projectNumber,
                            idcCodeList.value[index].projectType = item.projectType,
                            idcCodeList.value[index].organization = item.organization,
                            idcCodeList.value[index].entity = item.entity
                    }
                })
            } else {
                idcCodeList.value[index].projectNumber = ''
                idcCodeList.value[index].projectType = ''
            }
        }
        const projectNumberOps = (value, record) => {
            if (value === true) {
                if (record.entity || record.organization) {
                    projectNumlists.value = ((record.entity && record.organization) ? allRelation.value.filter(item => item.entity === record.entity).filter(item => item.organization === record.organization) :
                        (record.entity ? allRelation.value.filter(item => item.entity === record.entity) : allRelation.value.filter(item => item.organization === record.organization))).map((item, index) => {
                            return {
                                label: item.projectNumber,
                                value: item.projectNumber,
                                key: index,
                                organization: item.organization,
                                projectName: item.projectName,
                                projectType: item.projectType,
                                entity: item.entity
                            }
                        });
                } else {
                    projectNumlists.value = allRelation.value.map((item, index) => {
                        return {
                            label: item.projectNumber,
                            value: item.projectNumber,
                            key: index,
                            organization: item.organization,
                            projectName: item.projectName,
                            projectType: item.projectType,
                            entity: item.entity
                        }
                    });
                }
            }
        }
        const projectNameOps = (value, record) => {
            if (value === true) {
                if (record.entity || record.organization) {
                    projectNamelists.value = ((record.entity && record.organization) ? allRelation.value.filter(item => item.entity === record.entity).filter(item => item.organization === record.organization) :
                        (record.entity ? allRelation.value.filter(item => item.entity === record.entity) : allRelation.value.filter(item => item.organization === record.organization))).map(item => item.projectName)
                } else {
                    projectNamelists.value = allRelation.value.map(item => item.projectName)
                }
            }
        }
        const fileList = ref([])
        let messageTimer = null;
        const handleBeforeUpload = (file, list) => {
            return new Promise(() => {
                for (let i = 0; i < fileList.value.length; i++) {
                    if (fileList.value[i]) {
                        if (fileList.value[i].name === file.name) {
                            // settimeout 防止弹出多个消息提示
                            clearTimeout(messageTimer);
                            messageTimer = setTimeout(() => {
                                message.error("请不要选择重复的文件！");
                            });
                            return false;
                        }
                    }
                }
                const num = 3
                const info = "最多上传三个附件"
                if (list.length > num) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error(info);
                    });
                    return false;
                }
                if (fileList.value.length + 1 > num) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error(info);
                    });
                    return false;
                }
                if (file.size === 0) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error("文件为空文件，请检查后再次上传!");
                    });
                    return false;
                }
                const isLt50M = file.size / 1024 / 1024 < 50;
                if (!isLt50M) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error("文件大小限制50MB，请检查后再次上传!");
                    });
                    return false;
                }

                if (!file.type.includes("application/msword") &&
                    !file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
                    !file.type.includes("application/vnd.ms-powerpoint") &&
                    !file.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
                    !file.type.includes("application/vnd.ms-excel") &&
                    !file.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") &&
                    !file.type.includes("text/plain") &&
                    !file.type.includes("application/pdf") &&
                    !(file.name.slice(-3) === '.7z') &&
                    !file.type.includes("application/x-zip-compressed") &&
                    !(file.name.slice(-4) === '.rar')
                ) {
                    clearTimeout(messageTimer);
                    messageTimer = setTimeout(() => {
                        message.error("只可以上传doc,docx,ppt,pptx,xls,xlsx,txt,pdf,7z,zip,rar格式的文件！");
                    });
                    return false;
                }
                fileList.value = [...fileList.value, file];
            })
        };
        const clicktoshowtable = () => {
            showassignmentdata.value = false;
        }
        const showClick = () => {
            showassignmentdata.value = !showassignmentdata.value;
        }
        const modelValue = ref(false)
        const content = ref('')
        const showIconCom = ref('')
        const flag = ref()
        const modalData = ref()
        const handleOk = () => {
          if(flag.value === 1){
            const {file} = modalData.value
            const index = fileList.value.indexOf(file)
            fileList.value.splice(index, 1)
          }else if(flag.value === 2){
            const {index} = modalData.value
            dataList.value.splice(index, 1)
          }else if(flag.value === 3){
            const {index} = modalData.value
            assignmentdata.value.splice(index, 1)
          }else if(flag.value === 4){
            const {index} = modalData.value
            idcCodeList.value.splice(index, 1)
          }else if(flag.value === 5){
            const {record} = modalData.value
            tableListOthers.value.forEach((item, index) => {
              if (record.id == item.id) {
                tableListOthers.value.splice(index, 1);
              }
            })
          }else if(flag.value === 6){
            const {record} = modalData.value
            $api.organizationsDeleteFile(record.id, props.offering).then((res) => {
              if (res.msg == '当前有人员引用了该入职指引，不可删除') {
                message.error('当前有人员引用了该入职指引，不可删除！');
              } else {
                getWelcomeLetterTemplateManage(props.userId, props.offering)
                message.success('删除成功！');
              }

            })
          }
        }
        // 删除其他附件
        const deleteOtherFilesFun = (record) => {
            content.value = '确定删除这条数据吗?'
            showIconCom.value = 'ExclamationCircleOutlined'
            flag.value = 5
            modalData.value = {record}
            modelValue.value = true

            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    tableListOthers.value.forEach((item, index) => {
                        if (record.id == item.id) {
                            tableListOthers.value.splice(index, 1);
                        }
                    })
                },
            });*/
        }
        const handleConfirm = (type) => {
            if (type === 'edit') {
                let checkoutFlag = true;
                dataList.value.forEach(item => {
                    if (!item.position || !item.email) {
                        checkoutFlag = false
                        return message.warning('职位联系方式不能为空')
                    }
                })
                idcCodeList.value?.forEach(item => {
                    if (!item.projectNumber || !item.projectName || !item.entity || !item.organization) {
                        checkoutFlag = false
                        return message.warning('IDC列表code字段不能为空')
                    }
                })
                let arr = []
                arr = fileList.value.filter(item => item.id)
                let staffFileIds = []
                arr.forEach(item => {
                    staffFileIds.push(item.id)
                })
                const fileData = fileList.value.filter(item => item.uid)
                const formData = new FormData();
                fileData.forEach((item) => {
                    formData.append("file", item);
                });
                dataList.value.map((item, index) => {
                    return item.orderNumber = String(index);
                })
                idcCodeList.value.map((item, index) => {
                    return item.orderNumber = String(index);
                })
                const params = {
                    id: props.userId,
                    team: formState.orgThreeTwoList.toString(),
                    offering: formState.orgFourOneOneList.toString(),
                    templateName: formState.templateName,
                    subject: formState.engagementCode,
                    ccPerson: formState.ccPersonList,
                    remarks: remarks.value,
                    items: dataList.value.filter(item => item.fullName != '-'),
                    idcCodes: idcCodeList.value,
                    assignments: assignmentdata.value,
                    otherFileList: tableListOthers.value,
                    guideFileList: tableListEntry.value
                    // staffFileIds,
                    // staffWelcomeLetterContactsItems: dataList.value,
                    // staffIDCCodes: idcCodeList.value,
                    // // wlPl: assignmentdata.value[0].wlPl,
                    // wlAccount: assignmentdata.value[0].wlAccount,
                    // engagementDescription: assignmentdata.value[0].engagementDescription,
                    // engagementManager: assignmentdata.value[0].engagementManager,
                    // remarks: remarks.value,
                    // id: templateData.value.id
                }
                // if (params.items?.length > 0) {
                //     params.items.forEach(item => {
                //         if (item.position == "" || item.email == "") {
                //             return message.warning("职位与联系方式不能为空");
                //         }
                //     })
                // }
                if (checkoutFlag) {
                    $api.editDefineTemplate(params).then(res => {
                        if (res.code === 200) {
                            message.success(res.msg);
                            emit('doSearch')
                            emit('handleCancel')
                        }
                    })
                }
            } else {
                // if ((assignmentdata.value[0].wlPl || assignmentdata.value[0].wlAccount || assignmentdata.value[0].engagementDescription) && (!assignmentdata.value[0].engagementManager)
                // ) {
                //     return message.error('请填写Engagement Manager字段')
                // }
                let arr = []
                arr = fileList.value.filter(item => item.id)
                let staffFileIds = []
                arr.forEach(item => {
                    staffFileIds.push(item.id)
                })
                const fileData = fileList.value.filter(item => item.uid)
                const formData = new FormData();
                fileData.forEach((item) => {
                    formData.append("file", item);
                });
                dataList.value.map((item, index) => {
                    return item.orderNumber = String(index);
                })
                idcCodeList.value.map((item, index) => {
                    return item.orderNumber = String(index);
                })
                const params = {
                    team: formStateOthers.orgThreeTwoList.toString(),
                    offering: formStateOthers.orgFourOneOneList.toString(),
                    templateName: formStateOthers.templateName,
                    subject: formState.engagementCode,
                    ccPerson: formState.ccPersonList,
                    remarks: remarks.value,
                    items: dataList.value.filter(item => item.fullName != '-'),
                    idcCodes: idcCodeList.value,
                    assignments: assignmentdata.value,
                    otherFileList: tableListOthers.value,
                    guideFileList: tableListEntry.value
                }
                $api.saveAsDefineTemplate(params).then(res => {
                    if (res.code === 200) {
                        message.success(res.msg);
                        emit('doSearch')
                        emit('handleCancel')
                    }
                })
                    .catch(error => {
                        console.log('error', error);
                    });
            }

        }
        const onCancel = () => {
            return false
        }
        const fuc = (file) => {
            content.value = '确定删除这条数据吗?'
            showIconCom.value = 'ExclamationCircleOutlined'
            flag.value = 1
            modalData.value = {file}
            modelValue.value = true

            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    console.log(file)
                    const index = fileList.value.indexOf(file)
                    console.log(index)
                    fileList.value.splice(index, 1)
                },
                cancelText: '取消',
            });*/
        }
        const removeFile = (file) => {
            fuc(file)
            return false
        }
        const handledel = (index) => {
            content.value = '确定删除这条数据吗?'
            showIconCom.value = 'ExclamationCircleOutlined'
            flag.value = 2
            modalData.value = {index}
            modelValue.value = true

            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    dataList.value.splice(index, 1)
                },
                cancelText: '取消',
            });*/
        }
        const handleOrderDel = (index) => {
            content.value = '确定删除这条数据吗?'
            showIconCom.value = 'ExclamationCircleOutlined'
            flag.value = 3
            modalData.value = {index}
            modelValue.value = true

            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    assignmentdata.value.splice(index, 1)
                },
                cancelText: '取消',
            });*/
        }
        const handledelIdc = (index) => {
            content.value = '确定删除这条数据吗?'
            showIconCom.value = 'ExclamationCircleOutlined'
            flag.value = 4
            modalData.value = {index}
            modelValue.value = true

            /*Modal.confirm({
                title: '确定删除这条数据吗?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: '确定',
                onOk() {
                    idcCodeList.value.splice(index, 1)
                },
                cancelText: '取消',
            });*/
        }
        const handledadd = () => {
            dataList.value.push(
                {
                    description: '',
                    email: '',
                    fullName: '',
                    position: ''
                }
            )
        }
        const handledaddIdc = () => {
            idcCodeList.value.push(
                {
                    entity: '',
                    organization: '',
                    projectNumber: '',
                    projectName: '',
                    projectType: '',
                    templateId: dataList.value.length > 0 ? dataList.value[0].templateId : ''
                }
            )
        }
        const handledTransferOrder = () => {
            assignmentdata.value.push({
                wlPl: '',
                wlAccount: '',
                engagementDescription: '',
                engagementManager: ''
            })
        }
        const handleCancel = () => {
            emit('handleCancel')
        }
        //更新
        const update = (file) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("userId", props.userId);
            console.log(file, formData);

        }
        const onselectFun = (e) => {
            console.log(e);
        }
        let errorNum = 0;
        const accept = ref(`
                        .doc, .DOC,
                        .docx, .DOCX,
                        .ppt, .PPT,
                        .pptx, .PPTX
                        `);
        //更新前文件格式
        const beforeUpdate = (file, list) => {
            return new Promise(() => {
                const fileType = file.name.substring(
                    file.name.lastIndexOf(".") + 1,
                    file.name.length
                );
                const isLt10M = file.size / 1024 / 1024 < 10;
                const isGt0KB = file.size > 0;
                if (!isLt10M) {
                    message.error("文件最大10MB!");
                    errorNum++;
                    if (errorNum == list.length) errorNum = 0;
                } else if (!isGt0KB) {
                    message.error("不能上传0KB的文件!");
                    errorNum++;
                    if (errorNum == list.length) errorNum = 0;
                } else if (
                    ![
                        "doc", "DOC", "docx", "DOCX", "ppt", "PPT", "pptx", "PPTX",
                    ].includes(fileType)
                ) {
                    message.error(
                        file.name +
                        " 文件格式错误！" +
                        "只能上传doc、docx、ppt、pptx格式的文件"
                    );
                    errorNum++;
                } else {
                    //更新
                    update(file);
                }
            });
        };
        // team改变时更新offering下拉框内容
        const changeOrgThreeTwo = () => {
            formState.orgFourOneOneList = [];
            handleOrgChange(formState.orgThreeTwoList);
        };
        // team改变时更新offering下拉框内容
        const handleOrgChange = (val) => {
            if (val?.length) {
                $api
                    .getOrgFour({ condition: val })
                    .then((res) => {
                        orgFourOneOneOps.value = res.data.orgFourOneOne;
                    });
            } else {
                getOrgList();
            }
        };
        let tempOffering = ref('')
        const changeOrgFourOneOne = (e, type) => {
            if (e == undefined) {
                e = ''
            }
            tempOffering = e;
            offerObj.offering = e;
            formState.templateName = e;
            if (type == 'update') {
                $api
                    .getAllFiles(e)
                    .then((res) => {
                        tableListEntry.value = res.data.guideFileList;
                    });
            } else {
                $api
                    .getAllFiles(e)
                    .then((res) => {
                        tableListResume.value = res.data.templateFileList;
                        tableListEntry.value = res.data.guideFileList;
                        tableListOthers.value = res.data.otherFileList;
                        formState.engagementCode = res.data.emailSubject;
                        remarks.value = res.data.welcomeMessage.replace('null', '');
                        dataList.value = [
                            {
                                fullName: '-',
                                position: 'Line Manager',
                                email: '-',
                                description: '工作目标设定及安排，个人发展，绩效管理等'

                            }
                        ]
                        if (res.data.RMAndHRBPList?.length > 0) {
                            dataList.value = [...dataList.value, ...res.data.RMAndHRBPList]
                        }
                    });
                $api.getIdcData({ offering: e, entity: null }).then((res) => {
                    idcCodeList.value = res.data
                });
            }
        }
        // 另存为team改变时更新offering下拉框内容
        const changeOrgThreeTwoOthers = () => {
            formStateOthers.orgFourOneOneList = [];
            handleOrgChangeOthers(formStateOthers.orgThreeTwoList);
        };
        // 另存为team改变时更新offering下拉框内容
        const handleOrgChangeOthers = (val) => {
            if (val?.length) {
                $api
                    .getOrgFour({ condition: val })
                    .then((res) => {
                        orgFourOneOneOpsOthers.value = res.data.orgFourOneOne;
                    });
            }
        };
        const changeOrgFourOneOneOthers = (e) => {
            formStateOthers.templateName = e;
        }
        // 联系人列表位置记录 拖拽方法实现
        const position = {
            start: undefined,
            end: undefined,
            sourceEl: undefined,
        }
        // 排序
        const reorder = ({ start, end }) => {
            if (start !== undefined && end !== undefined) {
                if (start > end) {
                    // 当开始大于结束
                    let temp = dataList.value[start]
                    dataList.value.splice(start, 1);
                    dataList.value.splice(end, 0, temp)
                } else if (start < end) {
                    // 结束大于开始
                    let temp = dataList.value[start]
                    dataList.value.splice(start, 1)
                    dataList.value.splice(end, 0, temp)
                }
                let arr = dataList.value?.map((item) => item.id)
                emit("onDeviceList", arr)
            }
        }
        function customRow(_record, index) {
            if (index !== 0) {
                return {
                    style: {
                        cursor: "move",
                    },
                    // 鼠标移入
                    onMouseenter: (event) => {
                        // 兼容IE
                        let ev = event || window.event;
                        ev.target.draggable = true;
                    },
                    // 开始拖拽
                    onDragstart: (event) => {
                        // 兼容IE
                        let ev = event || window.event;
                        // 阻止冒泡
                        ev.stopPropagation();
                        // 得到源目标数据;
                        position.start = index
                        const tr = ev.target
                        position.sourceEl = tr
                    },
                    // 拖动元素经过的元素
                    onDragover: (event) => {
                        let ev = event || window.event;
                        // 阻止默认行为
                        ev.preventDefault();
                    },
                    // 松开
                    onDrop: (event) => {
                        let ev = event || window.event;
                        // 阻止默认行为
                        ev.preventDefault();
                        position.end = index
                        reorder(position);
                        animation(position)
                    },
                };
            } else {
                return;
            }
        }
        // 实现动画效果
        function animation({ start, end, sourceEl }) {
            // 48 是每行的高度，也可以自动获取，根据情况而定
            let count = 48 * (start - end)
            sourceEl.style.translate = `0px ${count}px`
            setTimeout(() => {
                sourceEl.style.transition = "all 0.5s"
                sourceEl.style.translate = `0px 0px`
            })
        }

        // IDC 拖拽位置记录 拖拽方法实现
        const positionIdc = {
            start: undefined,
            end: undefined,
            sourceEl: undefined,
        }
        // 排序
        const reorderIdc = ({ start, end }) => {
            if (start !== undefined && end !== undefined) {
                if (start > end) {
                    // 当开始大于结束
                    let temp = idcCodeList.value[start]
                    idcCodeList.value.splice(start, 1);
                    idcCodeList.value.splice(end, 0, temp)
                } else if (start < end) {
                    // 结束大于开始
                    let temp = idcCodeList.value[start]
                    idcCodeList.value.splice(start, 1)
                    idcCodeList.value.splice(end, 0, temp)
                }
            }
        }
        function customRowIdc(_record, index) {
            return {
                style: {
                    cursor: "move",
                },
                // 鼠标移入
                onMouseenter: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    ev.target.draggable = true;
                },
                // 开始拖拽
                onDragstart: (event) => {
                    // 兼容IE
                    let ev = event || window.event;
                    // 阻止冒泡
                    ev.stopPropagation();
                    // 得到源目标数据;
                    positionIdc.start = index
                    const tr = ev.target
                    positionIdc.sourceEl = tr
                },
                // 拖动元素经过的元素
                onDragover: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                },
                // 松开
                onDrop: (event) => {
                    let ev = event || window.event;
                    // 阻止默认行为
                    ev.preventDefault();
                    positionIdc.end = index
                    reorderIdc(positionIdc);
                    animation(positionIdc)
                },
            };
        }
        const updateIdcOffering = ref([])
        const updateIdcVisible = ref(false)
        const handledUpdateIdc = () => {
            updateIdcVisible.value = true
        }
        const closeUpdateIdc = () => {
            updateIdcVisible.value = false
        }
        //更改IDCcode
        const updateIdcData = (data) => {
            idcCodeList.value = data
        }
        //cc选中的人出现在最上方
        const ccPersonListChange = (state) => {
            if (formState.ccPersonList.length > 0 && state === true) {
                let selectCC = CClist.value.filter(item => formState.ccPersonList.includes(item.fullName + ' ' + item.email))
                CClist.value = selectCC.concat(CClist.value.filter(item => !formState.ccPersonList.includes(item.fullName + ' ' + item.email)))
            }
        }
        onMounted(() => {
            getEMOptions()
            getIdcOptions()
            // getstaffWelcomeLetterTemplate(props.userId)
            getWelcomeLetterTemplateManage(props.userId, props.offering)
            getOrgList()
            getHRBPAndRMDataFun()
            console.log('hhaha', props.offering)
        })
        return {
            labelCol: {span: 24,},
            wrapperCol: {span: 24},
            rulessave,
            templateData,
            CClist,
            dataList,
            columns,
            columnsFind,
            columns2,
            columns3,
            getEMOptions,
            getIdcOptions,
            positionlist,
            entityList,
            changeemail,
            projectNumberChange,
            clicktoshowtable,
            assignmentdata,
            idcCodeList,
            showassignmentdata,
            handleConfirm,
            fileList,
            handleBeforeUpload,
            removeFile,
            onCancel,
            handledel,
            handledadd,
            handleCancel,
            showClick,
            customRow,
            customRowIdc,
            reorder,
            reorderIdc,
            handledaddIdc,
            handledelIdc,
            projectNumlists,
            formState,
            changeOrgThreeTwo,
            orgThreeTwoOps,
            orgFourOneOneOps,
            getOrgList,
            columnsResume,
            tableListResume,
            previewVisible,
            preview,
            columnsEntry,
            columnsOthers,
            uploadOthersVisible,
            uploadOthersFiles,
            handleUploadOk,
            handleOrgChange,
            handledTransferOrder,
            handleOrderDel,
            handleOthersSave,
            othersSaveVisible,
            getWelcomeLetterTemplateManage,
            changeOrgFourOneOne,
            tableListEntry,
            tableListOthers,
            closePreviewModal,
            remarks,
            formStateOthers,
            handleOthersUploadOk,
            orgThreeTwoOpsOthers,
            orgFourOneOneOpsOthers,
            changeOrgThreeTwoOthers,
            handleOrgChangeOthers,
            changeOrgFourOneOneOthers,
            update,
            onselectFun,
            accept,
            beforeUpdate,
            uploadFiles,
            previewUrl,
            deleteOtherFilesFun,
            handleCloseModal,
            handleResetTable,
            uploadClick,
            deleteClick,
            modalVisible,
            templateId,
            tempOffering,
            offerObj,
            modalVisible1,
            handleCloseModal1,
            handleResetTable1,
            uploadClick1,
            CClistRh,
            getHRBPAndRMDataFun,
            changePosition,
            updateIdcVisible,
            handledUpdateIdc,
            closeUpdateIdc,
            updateIdcOffering,
            updateIdcData,
            projectNamelists,
            organizationList,
            entityChange,
            organizationChange,
            projectNameChange,
            allRelation,
            projectNumberOps,
            ccPersonListChange,
            projectNameOps,
            modelValue,
            content,
            showIconCom,
            handleOk,
            emailClick,
        };
    },
});
</script>
<style scoped lang="less">
@import "~@/style/aModal.less";
:deep(.ant-drawer .ant-drawer-content){
  padding-bottom: 0 !important;
}
.btn-box{
  overflow: auto;
}
.box-padding{
  padding: 0 24px;
}
.rms-form-item {
  margin-bottom: 6px;

  .label {
    margin-right: 10px;
  }

  .item {
    width: 31.05vw;
    overflow: hidden;
  }
}

.rms-form {
  position: relative;
  display: block;
  margin-top: 24px;
  border-bottom: none;
}

.annex-alert-info {
  margin-top: 20px;

  .ant-alert-description {
    p {
      margin-bottom: 0;
    }
  }

  :deep(.ant-alert-with-description .ant-alert-icon) {
    margin-top: -3px;
  }
}

:deep .ant-table-row {
  background: url("../../../../assets/iconfont/drap.png") no-repeat left;
  background-size: 15px 15px;
}

.gray-table {
  :deep .ant-table-row {
    background: url("../../../../assets/home/home.png") no-repeat left;
    background-size: 1px 1px;
  }
}

:deep .ant-spin-container {
  z-index: 1;
}

.operationLinkdel {
  color: #de685a;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.operationLinklist {
  color: #3182ce;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.btn-add {
  float: right;
  margin-top: 20px;
  z-index: 99;
}

.btn-addIdc {
  float: right;
  margin-bottom: 10px;
  z-index: 99;
}

.form-item-inline {
  width: 300px;
}

.others-files {
  float: right;
  margin: 20px 0;
  z-index: 99;
}

.ppt {
  color: #f16c41;
}

.word {
  color: #4f6bf6;
}

.zip {
  color: #ffc757;
}

.blue {
  color: #3182ce;
  margin: 8px 0;
}
.padding-btm {
  padding-bottom: 58px;
}
.add-comment {
  display: flex;
  p {
    margin-top: -4px;
  }
}
.gray {
  font-size: 16px;
  color: #999;
}
.img-svg{
  margin-right: 8px;
  line-height: 24px;
  width: 24px;
  color: #3182ce;
}
.feature-lineright {
  margin-top: 20px;
  z-index: 999;
  float: right;
}

.text {
  word-break: break-all
}

.operationLink-entry {
  font-size: 16px;
  font-weight: bold;
}

.table {
  margin-top: 30px;
}

.upload-entry {
  color: #fff;
}

.operationLinkgray {
  font-size: 12px;
  text-decoration: underline;
  color: #5a5959;
}

:deep .ant-form-item-label>label::after {
  content: ''
}

.email-them {
}

.syrms-form-item {
  .item {
    width: 800px;
  }
}

.remarks {
  width: 100%;
}

.ant-upload-text-alert{
  padding: 0 24px;
  color: #999;
}
.flex-box{
  display: flex;
  .icon-btn {
    display: flex;
    width: 24px;
    height: 24px;
    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
}

.font-color{
  color:#4d4d4d;
}
</style>
