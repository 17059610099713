<template>
  <section class="top-filter">
    <div class="search mb12">
      <a-space>
        <a-button type="primary" @click="handleExport">
          导出
        </a-button>
      </a-space>
    </div>
    <FilterLayout @doReset="handleReset"
                  @doSearch="doSearch"
                  @doCollapseEffect="handleResize"
                  ref="filterLayoutRef">
      <a-form
          class="form rms-form"
          name="custom-validation"
          ref="formRef"
          :model="formState"
          layout="inline"
      >
        <a-form-item label="Team" name="orgThreeTwoList" v-auth="['system:todoMarching:team']">
          <SelectWithAll
              class="form-item-inline"
              v-model:value="formState.orgThreeTwoList"
              placeholder="请选择"
              mode="multiple"
              showArrow
              allowClear
              :maxTagCount="1"
              @change="changeOrgThreeTwo"
          >
            <a-select-option
                v-for="(item, index) in orgThreeTwoOps"
                :key="index"
                :value="item"
            ><span :title="item">{{ item }}</span>
            </a-select-option>
          </SelectWithAll>
        </a-form-item>
        <a-form-item label="Offering" name="orgFourOneOneList" v-auth="['system:todoMarching:offering']">
          <SelectWithAll
              class="form-item-inline"
              v-model:value="formState.orgFourOneOneList"
              placeholder="请选择"
              mode="multiple"
              showArrow
              allowClear
              :maxTagCount="1"
          >
            <a-select-option
                v-for="(item, index) in orgFourOneOneOps"
                :key="index"
                :value="item"
            ><span :title="item">{{ item }}</span>
            </a-select-option>
          </SelectWithAll>
        </a-form-item>
        <a-form-item label="Engagement Description" name="demandCondition">
          <a-input
              class="item"
              v-model:value="formState.demandCondition"
              placeholder="项目名称"
              allowClear
              autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="需求编号" name="demandNumber">
          <a-input
              class="item"
              v-model:value="formState.demandNumber"
              placeholder="需求编号"
              allowClear
              autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="客户名称" name="customerCondition">
          <a-input
              class="item"
              v-model:value="formState.customerCondition"
              placeholder="客户名称/详细客户名称"
              allowClear
              autocomplete="off"
          />
        </a-form-item>
        <!--      匹配：只有PM显示Status -->
        <a-form-item label="Status" name="status" v-auth="['system:todoMarching:status']">
          <a-select
              class="item"
              v-model:value="formState.status"
              placeholder="请选择"
              :options="assignmentStatusOps"
              allowClear
              show-search
              :maxTagCount="1"
          />
        </a-form-item>
        <a-form-item label="搜索" name="nameCondition">
          <a-input
              class="item"
              v-model:value="formState.nameCondition"
              placeholder="人员姓名/ID Number/GGID"
              allowClear
              autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </FilterLayout>
  </section>
</template>
<script>
import {defineComponent, reactive, ref, onMounted, onActivated} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {cloneDeep} from "lodash";

export default defineComponent({
  name: "MatchingFilter",
  components: {},
  props: {
    tabId: String,
    orgThreeTwoOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    orgFourOneOneOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    isRM: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["search", "refresh-table", "getSize", "exportClick", "getOrgList"],
  setup(props, {emit}) {
    const {$api} = useGlobalPropertyHook();
    const formRef = ref();
    const formState = reactive({
      demandCondition: "",
      engagementCode: "",
      nameCondition: "",
      status: null,
      demandNumber: "",
      customerCondition: "",
      orgThreeTwoList: [],
      orgFourOneOneList: [],
    });

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      currentParams = cloneDeep(formState);
    }

    // 点击收起展开计算table所占高度
    const handleResize = () => {
      emit("getSize");
    }

    const doSearch = () => {
      updateParams();
      emit("search", currentParams);
    };

    const handleReset = () => {
      formRef.value.resetFields();
      updateParams();
      emit("search", currentParams);
      // 重新获取team offering下拉
      if(props.isRM) {
        emit("getOrgList")
      }
    };

    const assignmentStatusOps = ref([]);
    const getAssignmentTypes = () => {
      $api
          .getDictionaryList({
            params: {
              dictType: "todoListPreStatus",
            },
          })
          .then((res) => {
            assignmentStatusOps.value = res.data && res.data.todoListPreStatus ?
                res.data.todoListPreStatus.map(({dictCode, dictValue}) => ({
                  label: dictValue,
                  value: dictCode,
                })) : [];
          });
    };

    // team改变时更新offering下拉框内容
    const changeOrgThreeTwo = () => {
      formState.orgFourOneOneList = [];
      emit("handleOrgChange", formState.orgThreeTwoList);
    };

    const handleExport = () => {
      emit("exportClick", currentParams)
    }

    onMounted(() => {
      getAssignmentTypes();
    });

    onActivated(() => {
      emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      handleReset,
      assignmentStatusOps,
      updateParams,
      handleResize,
      changeOrgThreeTwo,
      handleExport,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;

  .search {
    text-align: right;
  }
}
</style>
