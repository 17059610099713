<template>
  <section class="org-list mt12">
    <a-table ref="todoListRef" :columns="columns" :data-source="todoList" :rowKey="(record, index) => index" :pagination="pagination" @change="handleTableChange">
      <template #action="{ record }">
<!--        <a-space>
          <span class="operationLinklist" @click="handleEdit(record, '2')">查看
          </span>
          <span class="operationLinklist" @click="handleEdit(record, '1')">编辑
          </span>
          <span class="operationLinklist delred" @click="deleteFun(record)">删除
          </span>
        </a-space>-->
        <ActionBtn v-bind="record" :btnList="btnList" @EyeOutlined="handleEdit(record, '2')" @FormOutlined="handleEdit(record, '1')" @DeleteOutlined="deleteFun(record)" />
      </template>
    </a-table>
    <WelcomeLetterTamplateEdit v-if="welcomeVisible" :title="titleTemplate" @doSearch="doSearch" :userId="userId" :offering="offering" :isFind="isFind"
      v-model:visible="welcomeVisible" @handleCancel="welcomeVisible = false">
    </WelcomeLetterTamplateEdit>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  nextTick,
  toRefs,
  onUnmounted,
} from "vue";
import WelcomeLetterTamplateEdit from '@/views/home/WelLetterManagement/components/WelcomeLetterTamplateEdit';
import { useGlobalPropertyHook } from "@/hooks/common.js";
// import {
//   todoListAssStatus
// } from "@/utils/formatStatus";
import { useTableHeight } from "@/utils/common";
import { cloneDeep } from "lodash";
import { isshow } from '@/utils/isshow.js'
import { message } from 'ant-design-vue';

import ModalBox from '@/components/ModalBox'
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '查看',
    icon: 'EyeOutlined',
    auth: []
  },
  {
    name: '修改',
    icon: 'FormOutlined',
    auth: []
  },
  {
    name: '删除',
    icon: 'DeleteOutlined',
    auth: []
  },
]
export default defineComponent({
  name: "TodoList",
  components: {
    WelcomeLetterTamplateEdit,
    ModalBox,
    ActionBtn
  },
  props: {
    tabId: String,
  },
  emits: ['onSwitchDetail', 'changerowlist'],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const todoListRef = ref();
    const titleTemplate = ref('编辑');
    const columns = [
      {
        title: "Team",
        dataIndex: "team",
        key: "team",
        ellipsis: true,
        align: "left",
      },
      {
        title: "Offering",
        dataIndex: "offering",
        key: "offering",
        ellipsis: true,
        align: "left",
      },
      {
        title: "Template Name",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        align: "left",
      },
      {
        title: "Operation Time",
        dataIndex: "operationTime",
        key: "operationTime",
        align: "left",
        ellipsis: true,
      },
      {
        title: "Operator",
        dataIndex: "operator",
        key: "operator",
        ellipsis: true,
        align: "left",
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 140,
        align: "left",
        fixed: "right"
      }
    ]

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40','100'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1
        pagination.pageSize = size
      },
    });
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      $api.deleteDefineTemplate(modalData.value).then(() => {
        doSearch(searchCondition);
        message.success("success");
      })
    }
    // 列表删除功能
    const deleteFun = (record) => {
      modalData.value = record.id
      content.value = '确定删除这条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
      /*Modal.confirm({
        title: '确定删除这条数据吗?',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          $api.deleteDefineTemplate(record.id).then(() => {
            doSearch(searchCondition);
            message.success("success");
          })
        },
      });*/
    }
    //新增编辑模板弹窗
    const welcomeVisible = ref(false)
    const userId = ref('')
    const isFind = ref(false)
    const offering = ref('')
    const handleEdit = (record, type) => {
      titleTemplate.value = type === '1' ? '编辑' : '查看'; //弹窗标题赋值
      isFind.value = type === '2' ? true : false; //查看弹窗赋值
      welcomeVisible.value = true;
      userId.value = record.id;
      offering.value = record.offering;
    }
    const sort = reactive({
      order: '',
      field: ''
    });

    const handleTableChange = (pag, filters, sorter) => {
      if (!sorter.order) {
        sorter.order = ""
        sorter.field = ""
      }
      Object.assign(sort, sorter)
      doSearch();
    };

    const todoList = ref([]);
    let searchCondition = {};
    const tableHeight = ref(0)

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };
    const doSearch = (searchParams = searchCondition) => {
      const params = {
        ...searchParams,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        sort
      };
      $api
        .welcomeLetterCustomTemplateList(params)
        .then((res) => {
          pagination.current = res.data?.pageIndex + 1;
          pagination.pageSize = res.data?.pageSize;
          pagination.total = res.data?.total;
          todoList.value = res.data.resource;
        });
    };

    const matchCustomRowFunc = (record) => {
      return {
        onDblclick: () => {
          const query = {
            preMatchingId: record.preMatchingId,
            source: 'MatchingList'// 与组件名称对应
          };

          emit('onSwitchDetail', query)
        },
      };
    };
    const WelcomeLetterCustomRowFunc = (record) => {
      return {
        onDblclick: () => {
          if (isshow('system:staffInfo:todolistWelcomeletter')) {
            emit('WelcomeLetteronDetail', record)
          }
        },
      };
    }

    const assignmentCustomRowFunc = (record) => {
      return {
        onDblclick: () => {
          let status;
          switch (record.status) {
            case "1":
            case "2":
            case "3":
            case "4":
              status = "1";
              break;
            case "5":
            case "6":
            case "7":
            case "8":
              status = "2";
              break;
            case "9":
            case "13":
              status = "3";
              break;
          }
          const query = {
            assignmentData: record.engagementCode,
            assignmentId: record.id,
            status,
            source: 'AssignmentDetails'// 与组件名称对应
          };
          emit('onSwitchDetail', query)
        },
      };
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    onMounted(() => {
      doSearch();
      getSize();
    });
    let arr = []
    const state = reactive({
      selectedRowKeys: [],
      selectedRows: [],
    });
    window.addEventListener("resize", getSize);
    const rowSelection = {
      selectedRowKeys: toRefs(state).selectedRowKeys,
      onSelect: (record, selected) => {
        if (selected) {
          arr.push(record)
        } else {
          const delIndex = arr.findIndex((val) => {
            return val.id === record.id
          })
          arr.splice(delIndex, 1)
        }
        emit('changerowlist', arr)
      },
      onChange: (selectedRowKeys, selectionRows) => {
        if (selectedRowKeys.length === todoList.value.length) {
          arr = cloneDeep(todoList.value)
          emit('changerowlist', arr)
        } else if (selectedRowKeys.length === 0) {
          arr = []
          emit('changerowlist', arr)
        }
        state.selectedRowKeys = selectedRowKeys;
        state.selectedRows = selectionRows;
      }
    };
    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      todoListRef,
      columns,
      pagination,
      todoList,
      searchClick,
      doSearch,
      handleTableChange,
      sort,
      matchCustomRowFunc,
      assignmentCustomRowFunc,
      WelcomeLetterCustomRowFunc,
      getSize,
      ...toRefs(state),
      tableHeight,
      rowSelection,
      handleEdit,
      welcomeVisible,
      deleteFun,
      titleTemplate,
      isFind,
      userId,
      offering,
      modelValue,
      modalData,
      content,
      showIconCom,
      handleOk,
      btnList
    };
  },
});
</script>
<style scoped lang="less">
.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 68px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    background: #f4f4f4;
  }
}

.operationLinklist {
  color:#3182ce;
  cursor: pointer;
  text-decoration: underline;
}

.delred {
  color: #de685a;
}
</style>
