<template>
  <section class="top-filter">
    <FilterLayout @doReset="handleReset" @doSearch="doSearch" @doCollapseEffect="handleResize" ref="filterLayoutRef">
      <template #customform>
        <a-form class="form rms-form" name="custom-validation" ref="formRef" :model="formState" layout="vertical">
          <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
            <SelectWithAll  v-model:value="formState.orgThreeTwoList" placeholder="请选择"
                           mode="multiple" showArrow allowClear :maxTagCount="1" @change="changeOrgThreeTwo">
              <a-select-option v-for="(item, index) in orgThreeTwoOps" :key="index" :value="item"><span :title="item">{{
                  item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Offering" name="orgFourOneOneList" class="rms-form-item">
            <SelectWithAll v-model:value="formState.orgFourOneOneList" placeholder="请选择"
                           mode="multiple" showArrow allowClear :maxTagCount="1">
              <a-select-option v-for="(item, index) in orgFourOneOneOps" :key="index" :value="item"><span :title="item">{{
                  item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Template Name" name="templateNameList" class="rms-form-item">
            <SelectWithAll v-model:value="formState.templateNameList" placeholder="请选择" mode="multiple" showArrow
                           :maxTagCount="1" allowClear>
              <a-select-option v-for="(item, index) in temlateOps" :key="index" :value="item"><span :title="item">{{
                  item }}</span>
              </a-select-option>
            </SelectWithAll>
          </a-form-item>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right">
          <Tooltip title="新增">
            <a-button
                class="right"
                type="primary"
                @click="addTemplate"
                v-auth="['system:task:add']"
            >
              <PlusOutlined />
            </a-button>
          </Tooltip>
        </div>
      </template>
    </FilterLayout>
    <WelcomeLetterTamplate @doSearch="doSearch" v-if="welcomeVisible" title="新增" v-model:visible="welcomeVisible"
      @handleCancel="welcomeVisible = false">
    </WelcomeLetterTamplate>
  </section>
</template>
<script>
import WelcomeLetterTamplate from '@/views/home/WelLetterManagement/components/WelcomeLetterTamplate';
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { cloneDeep } from "lodash";
import { dictionaryOptions } from "@/utils/common";
import Tooltip from '@/components/Tooltip'

export default defineComponent({
  name: "WelcomeLetterFilter",
  components: {
    PlusOutlined,
    WelcomeLetterTamplate,
    Tooltip
  },
  props: {
    tabId: String,
    orgThreeTwoOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    temlateOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    orgFourOneOneOps: {
      type: Array,
      default: () => {
        return []
      }
    },
    isRM: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  emits: ["search", "refresh-table", "getSize", "exportClick", "getOrgList"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const formState = reactive({
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      templateNameList: []
    });

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      currentParams = cloneDeep(formState);
    }

    // 点击收起展开计算table所占高度
    const handleResize = () => {
      emit("getSize");
    }

    const doSearch = () => {
      updateParams();
      emit("search", currentParams);
    };

    const handleReset = () => {
      formRef.value.resetFields();
      formState.onBoardBegin = ''
      formState.onBoardEnd = ''
      updateParams();
      emit("search", currentParams);
      // 重新获取team offering下拉
      if (props.isRM) {
        emit("getOrgList")
      }
    };
    //新增模板弹窗
    const welcomeVisible = ref(false)
    // 新增模板弹窗
    const addTemplate = () => {
      welcomeVisible.value = true
    }
    const handlecapgeminiStratDateChange = () => {
      formState.onBoardBegin = formState.capgeminiStratDate[0]
      formState.onBoardEnd = formState.capgeminiStratDate[1]
    }
    const statusOps = ref([]);
    const getAssignmentTypes = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "todoListPreStatus,staffStatus",
          },
        })
        .then((res) => {
          statusOps.value = dictionaryOptions(res.data.staffStatus);
        });
    };

    // team改变时更新offering下拉框内容
    const changeOrgThreeTwo = () => {
      formState.orgFourOneOneList = [];
      emit("handleOrgChange", formState.orgThreeTwoList);
    };
    onMounted(() => {
      getAssignmentTypes();
    });

    onActivated(() => {
      emit("refresh-table");
    });
    const towelcome = () => {
      emit("towelcome")
    }
    return {
      formState,
      formRef,
      doSearch,
      handleReset,
      updateParams,
      handleResize,
      changeOrgThreeTwo,
      statusOps,
      towelcome,
      handlecapgeminiStratDateChange,
      welcomeVisible,
      addTemplate
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  //margin-top: 30px;

  .search {
    text-align: right;
  }
}

.btn-add {
  float: right;
  margin: 10px 0;
}

.ant-row {
  display: flex;
  flex-flow: column!important;
  align-items: baseline!important;
}

:deep .ant-form-item-label > label::after{
  content:''
}
</style>
