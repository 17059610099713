<template>
  <section class="app-container" v-show="contentShowType === 'todoMain'">
    <!-- :destroyInactiveTabPane="true" 避免两个tab页table数据相互污染 -->
    <div class="titleTabs">
      <WelcomeLetterFilter ref="WelcomeLetterRef" :tabId="activeKey" :orgThreeTwoOps="orgThreeTwoOps"
        :orgFourOneOneOps="orgFourOneOneOps" :temlateOps="temlateOps" :isRM="isRM" @getSize="getSize"
        @search="handleSearch" @towelcome="towelcome" @getOrgList="getOrgList" @handleOrgChange="handleOrgChange"
        @getTemplateNames="getTemplateNames" :disabled="welcomedisabled"></WelcomeLetterFilter>
      <TodoList ref="WelcomeRef" :tabId="activeKey" @WelcomeLetteronDetail="WelcomeLetteronDetail($event)"
        @changerowlist="changerowlist($event)"></TodoList>
    </div>
    <WelcomeLetter v-if="welcomeVisible" title="Welcome Letter" :rowlist="rowlist" @handleCancel="handleWelcomeCancel"
      @update="doSearch" v-model:visible="welcomeVisible" />
    <component :is="contentShowType" v-if="contentShowType !== 'todoMain'" @onBackTodoList="onBackTodoList" />
  </section>
</template>

<script>
import {
  defineComponent,
  ref,
  provide,
  computed,
  onActivated,
  onMounted,
} from "vue";
import AssignmentFilter from "@/views/home/WelLetterManagement/components/AssignmentFilter";
import MatchingFilter from "@/views/home/WelLetterManagement/components/MatchingFilter";
import WelcomeLetterFilter from "@/views/home/WelLetterManagement/components/WelcomeLetterFilter";
import MatchingList from "@/views/home/ResourceManagement/Match/matchingList/index";
import TodoList from "@/views/home/WelLetterManagement/components/TodoList";
import AssignmentDetails
  from "@/views/home/ResourceManagement/Assignment/assignmentDetails/index";
import { useStore } from "vuex";
import { onBeforeRouteLeave } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";
import { downloadFile } from "@/utils/common";
import WelcomeLetter from '@/components/WelcomeLetter.vue';
import { isshow } from '@/utils/isshow.js'
export default defineComponent({
  name: "Assignment",
  components: { AssignmentFilter, TodoList, AssignmentDetails, MatchingList, MatchingFilter, WelcomeLetterFilter, WelcomeLetter },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const assListRef = ref();
    const matchingListRef = ref();
    const WelcomeRef = ref();
    const assignmentFilterRef = ref();
    const marchingFilterRef = ref();
    const activeKey = ref('3');
    const contentShowType = ref("todoMain");
    const todoQueryDetail = ref({});
    const store = useStore();

    const listRef = computed(() => activeKey.value === '1' ? assListRef.value : activeKey.value === '2' ? matchingListRef.value : WelcomeRef.value);

    provide("query", computed(() => todoQueryDetail));

    const handleSearch = (searchParams) => {
      listRef.value.searchClick(searchParams);
    };
    // 跳转详情
    const onSwitchDetail = (query) => {
      store.commit("SET_IS_TODO_STATUS", true);
      contentShowType.value = query.source;
      todoQueryDetail.value = query;
    };

    // 返回待办列表时间
    const onBackTodoList = () => {
      listRef.value.doSearch()
      contentShowType.value = "todoMain";
    };

    // 重新计算table所占高度
    const getSize = () => {
      listRef.value.getSize();
    };

    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const temlateOps = ref([]);

    //查询org3.2/org4.2
    const getOrgList = () => {
      $api
        .getOrgThree_FourList()
        .then((res) => {
          const { orgThreeTwo, orgFourOneOne } = res.data;
          orgThreeTwoOps.value = orgThreeTwo;
          orgFourOneOneOps.value = orgFourOneOne;
        });
    };

    // team改变时更新offering下拉框内容
    const handleOrgChange = (val) => {
      if (val?.length) {
        $api
          .getOrgFour({ condition: val.join() })
          .then((res) => {
            orgFourOneOneOps.value = res.data.orgFourOneOne;
          });
      } else {
        getOrgList();
      }
    };
    const getTemplateNames = () => {
      $api
        .getTemplateOps()
        .then((res) => {
          temlateOps.value = res.data;
        });
    }

    const accountParamList = ref([]);

    // 获取account下拉框内容
    const getAccountOptions = () => {
      $api.getTodoAccount().then((res) => {
        if (res.code === 200) {
          accountParamList.value = res.data.map((item) => {
            return {
              label: item.account,
              value: item.account_code,
            };
          });
        } else {
          message.error(res.msg);
        }
      });
    };
    const roles = computed(() => store.state.app.user.roles);
    const isRM = ref(roles.value.indexOf("2") !== -1);

    //切换tab页重新获取org下拉列表内容
    const initSelectData = () => {
      if (isRM.value) getOrgList();
    }

    const exportClick = (filterData) => {
      console.log(filterData)
      const exportFuc = activeKey.value === '1' ? 'doAssExport' : 'doMarchingExport'
      $api[exportFuc](filterData).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const welcomeVisible = ref(false)
    const towelcome = () => {
      welcomeVisible.value = true
    }
    const rowlist = ref([])
    const welcomedisabled = ref(true)
    const changerowlist = (list) => {
      welcomedisabled.value = list.length === 0 ? true : false
      rowlist.value = list
    }
    const WelcomeLetteronDetail = (record) => {
      rowlist.value = []
      rowlist.value[0] = record
      welcomeVisible.value = true
    }
    onMounted(() => {
      initSelectData()
      getAccountOptions()
      getTemplateNames()
    })

    onActivated(() => {
      contentShowType.value = "todoMain";
      // 页面切换回待办列表时设置为true
      store.commit("SET_IS_TODO_STATUS", true);
    })

    onBeforeRouteLeave(() => {
      // 页面离开待办列表时设置为false
      store.commit("SET_IS_TODO_STATUS", false);
    });
    const handleWelcomeCancel = () => {
      welcomeVisible.value = false
      listRef.value.doSearch()
    }
    //welcome letter tab标签页权限配置
    const show = isshow('system:staffInfo:welcomelettertab')

    return {
      activeKey,
      assListRef,
      matchingListRef,
      WelcomeRef,
      handleSearch,
      onSwitchDetail,
      contentShowType,
      todoQueryDetail,
      onBackTodoList,
      assignmentFilterRef,
      marchingFilterRef,
      getSize,
      orgThreeTwoOps,
      orgFourOneOneOps,
      getOrgList,
      handleOrgChange,
      accountParamList,
      getAccountOptions,
      initSelectData,
      exportClick,
      isRM,
      welcomeVisible,
      towelcome,
      changerowlist,
      rowlist,
      WelcomeLetteronDetail,
      handleWelcomeCancel,
      show,
      welcomedisabled,
      temlateOps,
      getTemplateNames
    };
  },
});
</script>

<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 10px 20px;
  background: #ffffff !important;
  border-radius: 8px;

  .titleTabs {
    // 大标题的tab样式
    @import "~@/style/titleTab.less";
  }

  :deep(.ant-tabs) {
    margin: 16px;

    .ant-tabs-tabpane {
      background-color: #fff;
      padding: 24px;
      min-height: 75vh;
    }
  }
}
:deep .rms-filter-btn-container{
  margin-top: 18px;
}
</style>
